import { Component, OnInit, ViewChild, inject, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { HeaderModule } from '../../header/header.module';
import { ModalModule } from '../../modal/modal.module';

import { AccountUser } from '../../_models/user';
import { Service, Price, Subscription } from '../../_models/service';

import { AccountService } from '../../_services/account.service';
import { SubscriptionService } from '../../_services/subscription.service';

import { ServiceSubscriptionComponent } from '../service-subscription/service-subscription.component';

@Component({
  selector: 'app-premium',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HeaderModule,
    ModalModule,
    ServiceSubscriptionComponent,
  ],
  templateUrl: './premium.component.html',
  styleUrl: './premium.component.scss'
})
export class PremiumComponent implements OnInit {

    @ViewChild('buyPremiumLite') buyPremiumLite: any;

    accountService = inject(AccountService);

    accountUser: AccountUser | undefined | null;

    service: Service;
    latestSubscription: Subscription;
    prices: Price[];
    periods: Price[] = []; // price periods, containing distinct set of monthly, annually etc

    currency: string = 'USD';

    constructor(
        private subscriptionService: SubscriptionService,
    ) {
        effect(() => {
            this.accountUser = this.accountService.loggedInAccountUserSig();
        });
    }

    ngOnInit(): void {
        this.getService();
    }

    getService(): void {
        let cId: number | undefined = undefined;
        let auId: number | undefined = undefined;

        if (this.accountUser) {
            auId = this.accountUser.getId();
        }

        // NOTE that currency can be coming from the subscription component as the user selects country
        // make sure not to overwrite it with the currency checks below
        this.subscriptionService.getService('u-premium-lite', '', this.currency, cId, auId).subscribe(
            s => {
                this.service = s;
                this.prices = s.getPrices();
                this.latestSubscription = new Subscription().deserialize(s.getLatestSubscription());

                // if the users currency is the same as one of the options of currencies for the service, select it
                let findPrice: Price | undefined = undefined;
                let findPriceStripe: Price | undefined = undefined;

                if (this.accountUser) {
                    findPrice = this.prices.find(p => p.currency == this.accountUser?.getCurrencyCode());
                    findPriceStripe = this.prices.find(p => p.currency == this.accountUser?.getStripeCustomerCurrency());
                }

                // if there is an active subscription, select the currency of the active subscription
                if (this.latestSubscription && this.latestSubscription.getCurrency()) {
                    this.currency = this.latestSubscription.getCurrency();

                // if not, select the currency of the user (stripe currency)
                } else if(this.accountUser && findPriceStripe) {
                    this.currency = this.accountUser.getStripeCustomerCurrency();

                } else if(s.selected_price && s.selected_price.currency) {
                    this.currency = s.selected_price.currency;

                // if not, select the currency of the user (this is third because the user might change currency)
                // NOTE this was removed as it was causing issues with the currency not being updated when the
                // user changed it in the subscription component
                //} else if(this.accountUser && findPrice) {
                //    currency = this.accountUser.getCurrencyCode();

                // if not, select USD as default
                } else if (!this.currency) {
                    this.currency = 'USD';
                }

                this.setPriceCurrency(this.currency);
            },
            error => {
            }
        );

    }

    setPriceCurrency(currency: string) {
        let price = this.prices.find(p => p.currency == currency && p.period == 'annually');

        if (!price) {
            price = this.prices.find(p => p.currency == currency);
        }

        if (price) {
            this.service.selected_price = price;
        }

        // to get the distinct prices by period(s) and the selected currency
        this.periods = this.prices.filter(price => price.currency === currency)
        .reduce((uniquePrices: Price[], price) => {
            if (!uniquePrices.find(p => p.period === price.period)) {
                uniquePrices.push(price);
            }
            return uniquePrices;
        }, []);

        // order the periods by annual first
        this.periods.sort((a, b) => {
            if (a.period === 'annually') {
                return -1;
            } else {
                return 1;
            }
        });
    }


    /*setService(s: Service) {
        this.service = s;
    }*/

    setPrice(p: Price) {
        this.service.selected_price = p;
    }

    getSavings() {

        if (!this.service) {
            return 0;
        }

        let monthlyPrice = this.service.prices.find(p => p.period == 'monthly' && p.currency == this.currency);
        let annuallPrice = this.service.prices.find(p => p.period == 'annually' && p.currency == this.currency);

        if (monthlyPrice && annuallPrice && monthlyPrice.price > 0 && annuallPrice.price > 0) {
            let monthlyPriceValue = monthlyPrice.price;
            let annuallPriceValue = annuallPrice.price;

            if (monthlyPriceValue > 0 && annuallPriceValue > 0) {
                let savings = monthlyPriceValue * 12 - annuallPriceValue;
                return savings;
            }
        }

        return 0;
    }

    getSavingsInPercent() {

        if (this.getSavings() > 0) {
            let monthlyPrice = this.service.prices.find(p => p.period == 'monthly' && p.currency == this.currency);

            if (monthlyPrice) {
                let savingsInPercent = this.getSavings() / (monthlyPrice.price * 12) * 100;
                return savingsInPercent;
            }
        }

        return 0;
    }

}
