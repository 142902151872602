import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '../../modal/modal.module';
import { RouterModule } from '@angular/router';

import { loadStripe } from '@stripe/stripe-js';

import { environment } from '../../../environments/environment';

//import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { Capacitor } from '@capacitor/core';

import { AccountUser } from '../../_models/user';
import { Company } from '../../_models/company';
import { Service, Price, Subscription, PaymentMethod } from '../../_models/service';

import { AccountService } from '../../_services/account.service'
import { AlertService } from '../../_services/alert.service';
import { CompanyService } from '../../_services/company.service';
import { SubscriptionService } from '../../_services/subscription.service';

import { CountrySelectComponent } from '../../country-select/country-select.component';
import { SlotTableComponent } from '../slot-table/slot-table.component';


@Component({
    selector: 'app-subscription',
    standalone: true,
    imports: [
      CommonModule,
      TranslateModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      ModalModule,
      CountrySelectComponent,
      SlotTableComponent,
    ],
    templateUrl: './subscription.component.html',
    styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

    env = environment.env;

    @ViewChild('viewSlotsModal') viewSlotsModal: any;
    @ViewChild('viewTermsModal') viewTermsModal: any;
    @ViewChild('viewPaymentMethodsModal') viewPaymentMethodsModal: any;

    _companyId: number;
    company: Company;

    @Input() set companyId(cId: number) {
        this._companyId = cId;

        if (cId) {
            if (!this.company || (this.company && this.company.id != cId)) {
                this.getCompany(cId);
            }
        }
    }

    _service: Service;
    _price: Price;

    prices: Price[] = [];

    @Input() set service(s: Service) {
        this._service = s;
        this.prices = s.getPrices();

        if (s.getLatestSubscription()) {
            this.subscription = new Subscription().deserialize(s.getLatestSubscription());

            // set default payment method
            if (this.paymentMethods) {
                for (let i = 0; i < this.paymentMethods.length; i++) {
                    //if (this.paymentMethods[i].id == this.subscription.default_payment_method) {
                    //    this.subscriptionDefaultPaymentMethod = this.paymentMethods[i];
                    //}
                }
            }
        }

        // update this.currencies with all currencies available for this service
        this.currencies = [];
        this.prices.forEach(p => {
            if (!this.currencies.includes(p.currency)) {
                this.currencies.push(p.currency);
            }
        });

    }

    @Input() set price(p: Price) {
        this._price = p;
        this._service.selected_price = p;
    }

    @Input() subscription: Subscription;

    @Input() type: string = "new"; // new, update

    @Output() reloadPricesWithCurrency = new EventEmitter<string>();
    @Output() reloadService = new EventEmitter<boolean>();
    @Output() activated = new EventEmitter<boolean>();
    @Output() updatedService = new EventEmitter<Service>();

    currencies: string[] = [];

    //selectedPlan: any;
    //selectedPlanNr: number = 0;
    //selectedPlanPeriod: string = "monthly";
    //selectedPlans: any;

    //selectedPlanSumUserPrice: number;
    //sumTax: number = 0;
    //sumNet: number;
    //sumTotal: number;
    //sumQuantity: number;

    stripe: any;
    elements: any;
    card: any;

    errorMessage: string;

    customerId: string;
    //customerCurrency: string;

    companyUsers: number;
    companyVATnr: string;

    countryCode: string;
    countryInEU: boolean;
    tax: number = 0;

    paymentMethodId: string | undefined;
    defaultPaymentMethod: PaymentMethod;
    selectedPaymentMethod: PaymentMethod;

    loggedInAccountUser: AccountUser | undefined | null;

    loadingCompany: boolean = false;
    loadingService: boolean = false;
    loadingAccountUser: boolean = false;
    loadingPaymentMethods: boolean = false;
    loadingConfirmCardPaymentModal: boolean = false;
    loading: boolean = false;
    thankYou: boolean = false;
    showBadgeTooltip: boolean = false;

    addCardBox: boolean = false;

    paymentMethods: PaymentMethod[] = [];
    subscriptionDefaultPaymentMethod: PaymentMethod;

    isNativePlatform: boolean = false;

    constructor(
        private accountService: AccountService,
        private alertService: AlertService,
        private companyService: CompanyService,
        private subscriptionService: SubscriptionService,
        private translate: TranslateService,
        @Inject(LOCALE_ID) public locale: string,
    ) {
    }

    async initStripe() {
        if (this.env == 'dev') {
            this.stripe = await loadStripe('pk_test_51I37H2H4kQOk6NUA0mgCjffmpur61IK7PGnTMlJhMyQ6ANOZHA528ZKfFjKy0UarABqcsApSAK8vM8RLPtKaH5pO00xTLmqVki');

        } else if (this.env == 'staging') {
            this.stripe = await loadStripe('pk_test_51I37H2H4kQOk6NUA0mgCjffmpur61IK7PGnTMlJhMyQ6ANOZHA528ZKfFjKy0UarABqcsApSAK8vM8RLPtKaH5pO00xTLmqVki');

        } else {
            // production
            this.stripe = await loadStripe('pk_live_51I37H2H4kQOk6NUAvgtuG4fseIFMJ1HMHQQCoAZhwueWmEF8jlsUns33ANKw7oVdEo5qSPotx57JFFQH3v91zbqB00T4Ck77O3');
        }

        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');
        this.card.mount('#card-element');
    }

    ngOnInit() {
        this.initStripe();

        //this.card = this.subscriptionService.getCard(this.elements);

        this.accountService.reloadLoggedInAccountUser();
        this.getLoggedInAccountUser();

        if (Capacitor.isNativePlatform()) {
            this.isNativePlatform = true;
        } else {
            this.isNativePlatform = false;
        }
    }

    ngOnDestroy() {
        this.card.unmount();
        //this.card.destroy();
    }

    getTranslatedSlotName() {
        if (this._service.getSlotName()) {
            const slotName = this._service.getSlotName();
            return this.translate.instant(slotName);
        }
        return this.translate.instant('slots');
    }

    isCardUpdateNeeded() {
        if (this.subscription && this.subscription.getStripeStatus() != 'canceled') {
            let status = this.subscription.getStripeStatus();
            // stripe statuses: incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid
            if (status == 'incomplete' || status == 'incomplete_expired' || status == 'past_due' || status == 'unpaid') {
                return true;
            }
        }
        return false;
    }

    getLoggedInAccountUser(): void {
        this.loggedInAccountUser = this.accountService.loggedInAccountUserSig();

        const au = this.loggedInAccountUser;

        if (au) {

            this.setCountry(au.getCountryCode(), au.isCountryInEu(), au.getCountryVat());

            // stripe customer id
            if (this._service.getPer() == 'user') {
                this.customerId = au.getStripeCustomerId();
                //this.customerCurrency = au.getStripeCustomerCurrency();
            }

            // if no company is set, and if user only has one company, set that company
            if (this._service.getPer() == 'company' || this._service.getPer() == 'company_user' || this._service.getPer() == 'job') {
                if (!this._companyId && au && au.getCompanies().length == 1) {
                    this._companyId = au.getCompanies()[0].id;
                    this.getCompany(this._companyId);
                }
            }

            this.getDefaultPaymentMethod();
            this.getPaymentMethods();
        }
    }

    /*getService(inCurrency: string) {
        this.loadingService = true;

        this.subscriptionService.getService(this._service.getSlug(), this._service.getPeriod(), inCurrency, this._companyId).subscribe(
            s => {
                this.errorMessage = "";
                this._service = s;
                if (s.getLatestSubscription()) {
                    this.subscription = new Subscription().deserialize(s.getLatestSubscription());
                }
                this.updatedService.emit(s);
                this.loadingService = false;
                //if (this._companyId)
                //    this.getCompany(this._companyId);

            },
            error => {
                this.loadingService = false;
            }
        );
    }*/

    getOrCreateStripeCustomer() {
        let cId: number | undefined = undefined;
        let auId: number | undefined = undefined;

        // user
        if (this._service.getPer() == 'user') {
            auId = this.loggedInAccountUser?.getId();

        // company, company_user, job
        } else if (this._service.getPer() == 'company' || this._service.getPer() == 'company_user' || this._service.getPer() == 'job') {
            cId = this._companyId;
        }

        this.subscriptionService.getOrCreateStripeCustomer(this.loggedInAccountUser?.getEmail(), cId, auId, this.countryCode).subscribe(
            result => {
                this.customerId = result['id'];
            },
            error => {
                this.loading = false;
                this.reloadService.emit(true);
            }
        );
    }

    getCompany(cId: number) {
        this.loadingCompany = true;

        this.companyService.getCompanyByUser(cId).subscribe(
            company => {
                this.company = company;

                // stripe customer id and count users
                if (this._service.getPer() == 'company' || this._service.getPer() == 'company_user' || this._service.getPer() == 'job') {
                    this.customerId = company.getStripeCustomerId();
                    //this.customerCurrency = company.getStripeCustomerCurrency();
                }
                this.companyUsers = company.getTotalUsers();
                this.companyVATnr = company.getVatNr();

                // if company has a different currency than the selected service
                // change the service to the right currency
                //if (company.getStripeCustomerCurrency() && this._service && company.getStripeCustomerCurrency() != this._service.getCurrencyCode()) {
                    /*this.errorMessage = "You have previously bought services in " + this.customerCurrency +
                                        ". Please change the currency to " + this.customerCurrency +
                                        " before you continue.";*/
                //    this.getService(this.customerCurrency);
                //}

                // set country code and tax
                if (company.getCountryId()) {
                    this.setCountry(company.getCountryCode(), company.getCountryInEU(), company.getCountryVat());
                }

                this.loadingCompany = false;

                /*if (this.countryCode == 'SE') {
                    this.sumTax = this.selectedPlanSumUserPrice * this.tax;
                    this.sumTax = parseFloat(this.sumTax.toFixed(2));
                }
                if (this.sumTax) {
                    this.sumTotal = this.selectedPlanSumUserPrice + this.sumTax;
                } else {
                    this.sumTotal = this.selectedPlanSumUserPrice;
                }*/

                // init plan
            //    this.selectedPlans = this.plans[this.getLanguage()][0]['monthly'];
            //    this.setPlan(this.selectedPlans[this.selectedPlanNr]);
            },
            error => {
                this.loadingCompany = false;
            }
        );
    }

    isCompanyAdmin() {
        if (this.company && this.loggedInAccountUser?.isCompanyAdminFor(this.company.getId())) {
            return true;
        }
        return false;
    }

    getLanguage() {
        if (navigator.language == 'sv' || this.locale == 'sv' || this.countryCode == 'SE') {
            return 'sv';

        } else {
            return 'en';
        }

    }

    setCountryFromSelect(country: any) {
        if (country) {
            this.setCountry(country.getCode(), country.inEu(), country.getVat());

            // is user has StripeCustomerCurrency
            if (this.loggedInAccountUser?.getStripeCustomerCurrency()) {
                const userPrice = this.prices.find(p => p.currency == this.loggedInAccountUser?.getStripeCustomerCurrency());

                if (userPrice) {
                    this.setPrice(userPrice);
                    return;
                }
            }

            const countryPrice = this.prices.find(p => p.currency == country.getCurrencyCode());

            // if found country price and user has no StripeCustomerCurrency
            if (countryPrice) {
                this.setPrice(countryPrice);
                return;
            }

            // if no country or user price found, try to find USD price
            const defaultPrice = this.prices.find(p => p.currency == 'USD');

            if (defaultPrice) {
                this.setPrice(defaultPrice);

            } else {
                // if no USD price, set the first price in the list
                this.setPrice(this.prices[0]);
            }

        }
    }

    setPrice(price: Price) {
        this._service.selected_price = price;
        this._price = price;
        this.reloadPricesWithCurrency.emit(price.currency);
    }

    setCountry(code: string, EU: boolean, Vat: number) {
        this.countryCode = code;
        this.countryInEU = EU;
        this.tax = Vat;

        //this.selectedPlans = this.plans[this.getLanguage()];
        //this.setPlan(this.selectedPlans[0]);
        //this.selectPlan();
        //this.setPlan(this.selectedPlans[this.selectedPlanNr]);
        //this.errorMessage = "";
    }

    getSumQuantity() {
        let quantity: number | null = 0;

        // if subscription exists and we are upgrading it from monthly to annually,
        // add the new quantity to the existing quantity
        if (this.subscription && this.subscription.getPeriod() == 'monthly' && this._service.getPeriod() == 'annually') {
            // if use slots, add the new quantity to the existing quantity
            if (this._service.getUseSlots()) {
                quantity = this.subscription.getQuantity() + (this._service.getQuantity() ?? 0);
            } else {
                quantity = this.subscription.getQuantity();
            }
        } else {
            quantity = this._service.getQuantity();
        }

        return quantity;
    }


    getNet(s: Service) {
        return (s.getPrice() ?? 0) * (this.getSumQuantity() ?? 0);
    }

    getTax(s: Service) {
        if (!this._service.isVatIncluded()) {
            let net = (s.getPrice() ?? 0) * (this.getSumQuantity() ?? 0);
            let tax = net * this.tax;
            if (tax > 0)
                return parseFloat(tax.toFixed(2));
            else
                return 0;
        } else
            return 0;
    }

    getTotal(s: Service) {
        return this.getNet(s) + this.getTax(s);
    }

    increaseQuantity() {
        this._service.setQuantity((this._service.getQuantity() ?? 0) + 1);
    }

    decreaseQuantity() {
        // if use slots, set the quantity to the min slots if its less than the min slots
        if (this._service.getUseSlots() && this._service.getMinSlots() > 1) {
            if ((this._service.getQuantity() ?? 0) > this._service.getMinSlots()) {
                this._service.setQuantity((this._service.getQuantity() ?? 0) - 1);
            }

        // if subscription exists and we are upgrading it from monthly to annually,
        // decrease the quantity to 0 is ok
        } else if (this.subscription && this.subscription.getPeriod() == 'monthly' && this._service.getPeriod() == 'annually') {
            if ((this._service.getQuantity() ?? 0) > 0) {
                this._service.setQuantity((this._service.getQuantity() ?? 0) - 1);
            }

        } else if ((this._service.getQuantity() ?? 0) > 1) {
            this._service.setQuantity((this._service.getQuantity() ?? 0) - 1);
        }
    }

    updateQuantity(event: any) {
        let q = parseInt(event.target.value);

        // if use slots, set the quantity to the min slots if its less than the min slots
        if (this._service.getUseSlots() && this._service.getMinSlots() > 1) {
            if (q < this._service.getMinSlots()) {
                this._service.setQuantity(this._service.getMinSlots());
            } else {
                this._service.setQuantity(q);
            }

        // if subscription exists and we are upgrading it from monthly to annually,
        // decrease the quantity to 0 is ok
        } else if (this.subscription && this.subscription.getPeriod() == 'monthly' && this._service.getPeriod() == 'annually') {
            if (q >= 0) {
                this._service.setQuantity(q);
            }

        // if quantity is greater than 1, set the quantity
        } else if (q > 1) {
            this._service.setQuantity(q);

        } else {
            this._service.setQuantity(1);
        }
    }

    canBuy() {

        if (Capacitor.isNativePlatform()) {
            return false;
        }

        if (!this.thankYou && this.loggedInAccountUser) {

            // if card update is needed
            if (this.isCardUpdateNeeded()) {
                return true;
            }

            // service is for user
            if (this._service.getPer() == 'user') {

                // doesnt have access to the service
                if (!this.loggedInAccountUser.hasAccess(this._service.getKey(), null)) {
                    return true;
                }

            // service is for company
            } else if (this.company && this._service.getPer() == 'company' || this._service.getPer() == 'company_user' || this._service.getPer() == 'job') {

                // doesnt have access to the service
                if (this.company && !this.loggedInAccountUser.hasAccess(this._service.getKey(), this.company.getId())) {
                    return true;
                }

                // is using slots, can buy more
                if (this.company && this._service.getUseSlots()) {
                    return true;
                }

            }

        }

        return false;
    }

    subscribe() {

        if (!this.countryCode) {
            this.errorMessage = "No country has been selected";

        } else if (this.company && this.countryInEU && this.countryCode != 'SE' && !this.companyVATnr) {
            this.errorMessage = "No VAT number provided";

        } else {
            this.loading = true;

            this.getOrCreateStripeCustomer();

            // if no payment method was selected create a new one
            if (!this.paymentMethodId) {
                this.createPaymentMethod();

            } else {
                // if payment method was selected, update or create the subscription
                // do not update the subscription if its canceled
                if (this.subscription && this.subscription.getStripeStatus() != 'canceled') {
                    this.updateSubscription();
                } else {
                    this.createSubscription();
                }
            }

        }
    }

    setNewCard() {
        this.addCardBox = true;
        this.paymentMethodId = undefined;
        this.viewPaymentMethodsModal.close();
    }

    getPaymentMethods() {
        this.loadingPaymentMethods = true;

        // get the users payment methods
        this.subscriptionService.getLoggedInAccountUserStripePaymentMethods().subscribe(
            pm => {
                this.paymentMethods = pm;

                // select the first payment method thats not expired
                //if (pm.length > 0 && !pm[0].isCardExpired())
                //    this.paymentMethodId = pm[0].id;

                /*const findDefault = this.paymentMethods.find(pm => pm.getId() == this.defaultPaymentMethod.getId());

                if (findDefault) {
                    this.paymentMethodId = findDefault.getId();
                }

                for (let i = 0; i < this.paymentMethods.length; i++) {
                    if (!pm[i].isCardExpired()) {
                        this.paymentMethodId = pm[i].id;
                        break;
                    }
                }*/

                this.loadingPaymentMethods = false;

            },
            error => {
                this.loadingPaymentMethods = false;
            }
        );
    }

    getDefaultPaymentMethod() {
        this.loadingPaymentMethods = true;

        // get the users payment methods
        this.subscriptionService.getLoggedInAccountUserDefaultStripePaymentMethod().subscribe(
            pm => {
                this.paymentMethodId = pm.getId();
                this.defaultPaymentMethod = pm;
                this.selectedPaymentMethod = pm;

                this.loadingPaymentMethods = false;
            },
            error => {
                this.loadingPaymentMethods = false;
                this.addCardBox = true;
            }
        );
    }

    detachPaymentMethod(pm: PaymentMethod) {
        this.loadingPaymentMethods = true;

        if (confirm("Are you sure you want to remove this payment method?")) {

            this.subscriptionService.detachPaymentMethod(pm.getId()).subscribe(
                result => {
                    this.getPaymentMethods();
                },
                error => {
                    this.loadingPaymentMethods = false;
                }
            );

        }
    }

    selectPaymentMethod(pm: PaymentMethod) {
        this.paymentMethodId = pm.getId();
        this.selectedPaymentMethod = pm;
        this.addCardBox = false;
        this.errorMessage = "";

        this.getOrCreateStripeCustomer();

        this.setDefaultCard();
    }

    addCard() {
        this.loadingPaymentMethods = true;
        this.errorMessage = "";

        this.createPaymentMethod();
    }

    cancelCard() {
        this.addCardBox = false;
        this.errorMessage = "";
    }

    setDefaultCard() {
        this.loading = true;

        // if no payment method was selected update to the new one
        if (!this.paymentMethodId) {
            this.loading = false;
            this.errorMessage = "Add or select a payment card.";
            return
        }

        /*if (!this.subscription) {
            this.loading = false;
            this.errorMessage = "No subscription selected.";
            return;
        }*/

        let data = {
            'paymentMethodId': this.paymentMethodId,
        };

        if (this.subscription) {
            data['subscriptionId'] = this.subscription.getStripeSubscriptionId();
        }

        this.subscriptionService.setDefaultStripeCard(data).subscribe(
            invoice => {
                this.loading = false;
                this.errorMessage = "";

                if (invoice && invoice.hasOwnProperty('payment_intent')) {

                    let paymentIntent = invoice['payment_intent'];

                    // needs further action (ex. 3D secure)
                    if (paymentIntent && (paymentIntent['status'] == 'requires_action' || paymentIntent['status'] == 'requires_payment_method')) {
                        this.loadingConfirmCardPaymentModal = true;
                        this.confirmCardPaymentModal(paymentIntent);
                    } else {
                        this.alertService.success("Default card set!");
                        this.viewPaymentMethodsModal.close();
                        this.getPaymentMethods();
                    }


                } else {
                    this.alertService.success("Default card set!");
                    this.viewPaymentMethodsModal.close();
                    this.getPaymentMethods();
                    //this.alertService.success("Subscription activated!");
                    //this.activated.emit(true);
                    //this.thankYou = true;
                }

                this.accountService.reloadLoggedInAccountUser();
                this.getLoggedInAccountUser();

            },
            e => {
                this.loading = false;
                this.errorMessage = e.error.detail;
                //this.errorMessage = "An error occurred while updating your subscription. Please try again later.";
            }
        );
    }

    // create a new payment method via frontend because the card cant be sent to backend
    createPaymentMethod() {

        /*if (!this.customerId) {
            this.loading = false;
            this.errorMessage = "No customer provided.";
            return;
        }*/

        return this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
        })
        .then((result) => {
            if (result.error) {
                this.errorMessage = result.error.message;
                this.loading = false;
            } else {
                this.paymentMethodId = result.paymentMethod.id;

                if (this.paymentMethodId) {
                    this.subscriptionService.attachPaymentMethodToCustomer(this.paymentMethodId, this.customerId).subscribe(
                        result => {
                            this.addCardBox = false;
                            this.card.clear();
                            this.loadingPaymentMethods = false;

                            this.getDefaultPaymentMethod();
                            this.getPaymentMethods();
                            this.accountService.reloadLoggedInAccountUser();
                        },
                        error => {
                            this.loadingPaymentMethods = false;
                            if (error.hasOwnProperty('error') && error.error.hasOwnProperty('detail')) {
                                this.errorMessage = error.error.detail;
                            } else {
                                this.errorMessage = "An error occurred while adding your card. Please try again later.";
                            }
                        }
                    );
                }

                // continue creating a new subscription with this payment method
                /*if (this.type == 'new') {
                    // if payment method was selected, update or create the subscription
                    // do not update the subscription if its not active
                    if (this.subscription && this.subscription.isActive()) {
                        this.updateSubscription();
                    } else {
                        this.createSubscription();
                    }

                // update an existing subscription with this payment method
                } else if (this.type == 'update') {
                    this.retryInvoice();
                }*/

            }
        });
    }

    /*updatePaymentMethod() {
        return this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
        })
        .then((result) => {
            if (result.error) {
                this.errorMessage = result.error.message;
                this.loading = false;
            } else {
                this.paymentMethodId = result.paymentMethod.id;

                //this.retryInvoice();
            }
        });
    }*/

    retryInvoice() {
        let invoiceId = this.subscription.getStripeInvoiceIdPaymentActionRequired();

        let data = {
            'paymentMethodId': this.paymentMethodId,
            'customerId': this.customerId,
            'invoiceId': invoiceId,
        };

        this.subscriptionService.retryInvoice(data).subscribe(
            inv => {
                this.loading = false;
                this.errorMessage = "";

                let paymentIntent = inv['payment_intent'];

                // needs further action (ex. 3D secure)
                if (paymentIntent['status'] == 'requires_action' || paymentIntent['status'] == 'requires_payment_method') {
                    this.loadingConfirmCardPaymentModal = true;
                    this.confirmCardPaymentModal(paymentIntent);

                } else {
                    this.alertService.success("Subscription activated!");
                    this.activated.emit(true);
                    this.thankYou = true;
                }
            },
            error => {
                this.loading = false;
                if (error.message) {
                    this.errorMessage = error.message;
                } else {
                    this.errorMessage = error;
                }
            }
        );

    }

    createSubscription() {

        let priceMonth = 0;
        let priceYear = 0;

        if (this._service.getPeriod() == 'monthly') {
            priceMonth = (this._service.getPrice() ?? 0) * (this._service.getQuantity() ?? 0);
            priceYear = (this._service.getPrice() ?? 0) * 12 * (this._service.getQuantity() ?? 0);

        } else if (this._service.getPeriod() == 'annually') {
            priceMonth = ((this._service.getPrice() ?? 0) * (this._service.getQuantity() ?? 0)) / 12;
            priceYear = (this._service.getPrice() ?? 0) * (this._service.getQuantity() ?? 0);
        }

        let quantity = this._service.getQuantity();

        let data = {
                'paymentMethodId': this.paymentMethodId,
                'customerId': this.customerId,
                'priceId': this._service.getStripePriceId(),
                'quantity': quantity,
                'countryCode': this.countryCode,
                'companyId': this.company ? this.company.getId() : undefined,
                'VATnr': this.companyVATnr,
                'pricePerMonth': priceMonth,
                'pricePerYear': priceYear,
                'currency': this._service.getCurrencyCode(),
                'period': this._service.getPeriod(),
                'serviceId': this._service.getId(),
                'trialPeriodDays': this._service.getTrialPeriodDays()
                //'type': this._service.type,
            };

        this.subscriptionService.createStripeSubscription(data).subscribe(
            sub => {
                this.loading = false;
                this.errorMessage = "";

                this.subscription = new Subscription().deserialize(sub['subscription']);

                let paymentIntent = sub['stripe_subscription']['latest_invoice']['payment_intent'];

                // needs further action (ex. 3D secure)
                if (paymentIntent && (paymentIntent['status'] == 'requires_action' || paymentIntent['status'] == 'requires_payment_method')) {
                    this.loadingConfirmCardPaymentModal = true;
                    this.confirmCardPaymentModal(paymentIntent);

                } else {
                    this.alertService.success("Subscription activated!");
                    this.activated.emit(true);
                    this.thankYou = true;
                }

                this.accountService.reloadLoggedInAccountUser();
                this.getLoggedInAccountUser();

            },
            e => {
                this.loading = false;
                this.errorMessage = e.error.detail;
            }
        );
    }

    updateSubscription() {

        // do not update the subscription if its not active
        if (!this.subscription.isActive()) {
            this.createSubscription();
            return;
        }

        this.loading = true;

        let quantity = this._service.getQuantity();

        // if subscription is already active and service is using slots, add the new quantity to the existing quantity
        if (this.subscription && this.subscription.isActive() && this._service.getUseSlots()) {
            quantity = this.subscription.getQuantity() + (this._service.getQuantity() ?? 0);
        }

        let priceMonth = 0;
        let priceYear = 0;

        if (this._service.getPeriod() == 'monthly') {
            priceMonth = (this._service.getPrice() ?? 0) * (quantity ?? 0);
            priceYear = (this._service.getPrice() ?? 0) * 12 * (quantity ?? 0);

        } else if (this._service.getPeriod() == 'annually') {
            priceMonth = (this._service.getPrice() ?? 0) * (quantity ?? 0) / 12;
            priceYear = (this._service.getPrice() ?? 0) * (quantity ?? 0);
        }

        // if subscription exists, we can upgrade from monthly to annual but not from annual to monthly
        if (this.subscription && this.subscription.getPeriod() == 'annually' && this._service.getPeriod() == 'monthly') {
            this.loading = false;
            this.errorMessage = "You can't downgrade from annual to monthly.";
            return;
        }

        let data = {
            'paymentMethodId': this.paymentMethodId,
            'subscriptionId': this.subscription.getStripeSubscriptionId(),
            'priceId': this._service.getStripePriceId(),
            'quantity': quantity,
            'pricePerMonth': priceMonth,
            'pricePerYear': priceYear,
            'countryCode': this.countryCode,
            'currency': this._service.getCurrencyCode(),
            'period': this._service.getPeriod()
        };

        this.subscriptionService.updateStripeSubscription(data).subscribe(
            result => {
                this.loading = false;
                this.errorMessage = "";

                if (result && result.hasOwnProperty('payment_intent')) {

                    let paymentIntent = result['payment_intent'];

                    // needs further action (ex. 3D secure)
                    if (paymentIntent && (paymentIntent['status'] == 'requires_action' || paymentIntent['status'] == 'requires_payment_method')) {
                        this.loadingConfirmCardPaymentModal = true;
                        this.confirmCardPaymentModal(paymentIntent);

                    } else {
                        this.alertService.success("Subscription activated!");
                        this.activated.emit(true);
                        this.thankYou = true;
                    }

                } else {
                    this.alertService.success("Subscription activated!");
                    this.activated.emit(true);
                    this.thankYou = true;
                }


                this.accountService.reloadLoggedInAccountUser();
                this.getLoggedInAccountUser();

            },
            e => {
                this.loading = false;
                this.errorMessage = "An error occurred while updating your subscription. Please try again later.";
            }
        );

    }

    confirmCardPaymentModal(paymentIntent) {
        return this.stripe.confirmCardPayment(paymentIntent['client_secret'], { payment_method: this.paymentMethodId, })
        .then((result) => {
            if (result.error) {
                // Start code flow to handle updating the payment details.
                // Display error message in your UI.
                // The card was declined (i.e. insufficient funds, card has expired, etc).
                this.alertService.error(result.error.message);
                this.errorMessage = result.error.message;
                this.loadingConfirmCardPaymentModal = false;
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    // Show a success message to your customer.
                    // There's a risk of the customer closing the window before the callback.
                    // We recommend setting up webhook endpoints later in this guide.
                    this.alertService.success("Subscription activated!");
                    this.activated.emit(true);
                    this.thankYou = true;
                    this.loadingConfirmCardPaymentModal = false;
                }
            }
        })
        .catch((error) => {
            this.errorMessage = error.message;
        });
    }

}
