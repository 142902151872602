import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceComponent } from './service.component';
import { PremiumComponent } from './premium/premium.component';

const routes: Routes = [
    { path: 'premium', component: PremiumComponent },
    { path: 'subscription/:subscriptionId', component: ServiceComponent },
    { path: 's/:serviceSlug', component: ServiceComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceRoutingModule { }
