import { Deserializable } from "./deserializable";


export class Service implements Deserializable {
    id: number;
    name: string;
    key: string;
    slug: string;
    locale: string;
    //period: string;
    //stripe_price_id: string;
    trial_period_days: number;
    per: string;
    use_slots: boolean;
    min_slots: number;
    slot_name: string;
    iterations: number;
    prices: Price[];
    selected_price: Price;
    //original_price: number;
    //discounted_price: number;
    //discount: number;
    //currency: string;
    vat_included: boolean;
    intro: any;
    description: any;
    terms: string;
    included: any;
    latest_subscription: Subscription;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.prices = this.prices.map(price => new Price().deserialize(price));
        this.selected_price = new Price().deserialize(this.selected_price);
        return this;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getKey() {
        return this.key;
    }

    getSlug() {
        return this.slug;
    }

    getLocale() {
        return this.locale;
    }

    getTrialPeriodDays() {
        return this.trial_period_days;
    }

    getPer() {
        return this.per;
    }

    getUseSlots() {
        return this.use_slots;
    }

    getMinSlots() {
        return this.min_slots;
    }

    getSlotName() {
        return this.slot_name;
    }

    getIterations() {
        return this.iterations;
    }

    getPrices() {
        return this.prices;
    }

    getSelectedPrice() {
        return this.selected_price;
    }

    getPrice() {
        if (this.getSelectedPrice()) {
            return this.getSelectedPrice().getPrice();
        }
        return 0;
    }

    getMinPrice() {
        if (this.getSelectedPrice()) {

            if (this.getUseSlots() && this.getMinSlots() > 1) {
                return this.getSelectedPrice().getPrice() * this.getMinSlots();
            }

            return this.getSelectedPrice().getPrice();
        }
        return null;
    }


    getCurrencyCode() {
        if (this.getSelectedPrice())
            return this.getSelectedPrice().getCurrencyCode();
        return null;
    }

    getCurrencySymbol() {
        if (this.getCurrencyCode() == 'EUR')
            return '€';
        if (this.getCurrencyCode() == 'USD')
            return '$';
        if (this.getCurrencyCode() == 'SEK' || this.getCurrencyCode() == 'NOK' || this.getCurrencyCode() == 'DKK')
            return 'kr';

        return '';
    }

    getPeriod() {
        if (this.getSelectedPrice())
            return this.getSelectedPrice().getPeriod();
        return null;
    }

    getStripePriceId() {
        if (this.getSelectedPrice())
            return this.getSelectedPrice().getStripePriceId();
        return null;
    }

    getQuantity() {
        if (this.getSelectedPrice())
            return this.getSelectedPrice().getQuantity();
        return null;
    }

    setQuantity(quantity: number) {
        if (this.getSelectedPrice())
            this.getSelectedPrice().setQuantity(quantity);
    }

    isVatIncluded() {
        return this.vat_included;
    }

    getIntro(lang: string = 'en') {
        try {
            // Attempt to parse the intro as JSON
            const introJson = JSON.parse(this.intro);

            // Check if the parsed object is an array
            if (Array.isArray(introJson)) {
                const langObj = introJson.find(obj => obj.hasOwnProperty(lang));
                return langObj ? langObj[lang] : '';
            }
        } catch (e) {
            // If it's not JSON, return the original string
            return this.intro;
        }
    }

    getDescription(lang: string = 'en') {
        try {
            // Attempt to parse the description as JSON
            const descriptionJson = JSON.parse(this.description);

            // Check if the parsed object is an array
            if (Array.isArray(descriptionJson)) {
                const langObj = descriptionJson.find(obj => obj.hasOwnProperty(lang));
                return langObj ? langObj[lang] : '';
            }
        } catch (e) {
            // If it's not JSON, return the original string
            return this.description;
        }
    }

    getTerms() {
        return this.terms;
    }

    getIncluded() {
        return this.included; // json
    }

    getIncludedText(element: any, lang: string = 'en') {
        if (lang) {
            return element[lang];

        } else if (element.name) {
            return element.name;
        }
        return null;
    }

    getIncludedInfoText(element: any, lang: string = 'en') {
        if (lang) {
            if (element['info_' + lang])
                return element['info_' + lang];
        }
        return null;
    }

    getActiveSubscription() {
        return this.latest_subscription;
    }

    getLatestSubscription() {
        return this.latest_subscription;
    }

}

export class Price implements Deserializable {
    id: number;
    service: number;
    stripe_price_id: string;
    price: number;
    currency: string;
    period: string;
    quantity: number;
    quantity_from: number;
    quantity_to: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getServiceId() {
        return this.service;
    }

    getStripePriceId() {
        return this.stripe_price_id;
    }

    getPrice() {
        return this.price || 0;
    }

    getCurrencyCode() {
        return this.currency;
    }

    getPeriod() {
        return this.period;
    }

    getQuantity() {
        return this.quantity;
    }

    setQuantity(quantity: number) {
        this.quantity = quantity;
    }

    getQuantityFrom() {
        return this.quantity_from;
    }

    getQuantityTo() {
        return this.quantity_to;
    }

    getPricePerMonth() {
        if (this.period === 'annually') {
            return this.price / 12;
        }
        return this.price;
    }

}

export class Subscription implements Deserializable {
    id: number;
    account: number;
    account_user: number;
    company: number;
    service_name: string;
    service_key: string;
    service_unit_price: number;
    service_currency: string;
    service_slug: string;
    service_per: string;

    stripe_customer_id: string;
    stripe_subscription: string;
    stripe_subscription_id: string;
    stripe_product_id: string;
    stripe_price_id: string;
    stripe_payment_method_id: string;
    stripe_invoice_id_payment_action_required: string;
    stripe_status: string;

    trial_end: any;
    current_period_start: any;
    current_period_end: any;
    renews_at: any;
    cancels_at: any;
    cancel_at_period_end: boolean;

    type: string;
    period: string;
    quantity: number;
    currency: string;
    vat_included: boolean;

    price_per_month: number;
    price_per_year: number;

    slots: Slot[];
    slot_count: number;
    slot_available_count: number;
    slot_used_count: number;
    slot_name: string;

    created: any;
    created_by: number;

    deserialize(input: any): this {
        Object.assign(this, input);

        if (this.slots) {
            this.slots = this.slots.map(slot => new Slot().deserialize(slot));
        }

        return this;
    }

    getId() {
        return this.id;
    }

    getAccountId() {
        return this.account;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getCompanyId() {
        return this.company;
    }

    getServiceName() {
        return this.service_name;
    }

    getServiceKey() {
        return this.service_key;
    }

    getStripeCustomerId() {
        return this.stripe_customer_id;
    }

    getStripeSubscriptionId() {
        return this.stripe_subscription_id;
    }

    getStripeSubscription() {
        return this.stripe_subscription;
    }

    getStripeProductId() {
        return this.stripe_product_id;
    }

    getStripePriceId() {
        return this.stripe_price_id;
    }

    getStripePaymentMethodId() {
        return this.stripe_payment_method_id;
    }

    getStripeInvoiceIdPaymentActionRequired() {
        return this.stripe_invoice_id_payment_action_required;
    }

    getStripeStatus() {
        return this.stripe_status;
    }

    getTrialEnd() {
        return this.trial_end;
    }

    getCurrentPeriodStart() {
        return this.current_period_start;
    }

    getCurrentPeriodEnd() {
        return this.current_period_end;
    }

    // takes into account both current_period_end and renews_at
    getRenewsAt() {

        return this.renews_at;

        /*let renews_at = new Date(this.renews_at);
        let current_period_end = new Date(this.current_period_end);

        if (renews_at > current_period_end && !this.getCancelAtPeriodEnd()) {
            return renews_at;
        }
        return current_period_end;*/
    }

    getCancelsAt() {

        // if we have a cancel_at_period_end set, end subscription at current_period_end
        if (this.getCancelAtPeriodEnd()) {
            return this.current_period_end;
        }

        // if we have a cancels_at date set, and cancel_at_period_end is not set
        // then subscription should end at cancels date
        if (this.cancels_at) {
            let cancels_at = new Date(this.cancels_at);
            let current_period_end = new Date(this.current_period_end);

            if (cancels_at > current_period_end && !this.getCancelAtPeriodEnd()) {
                return this.cancels_at;
            }
        }

        if (this.cancels_at && this.isActive()) {
            return this.cancels_at;
        }


        return null;
    }

    isActive() {
        return this.getStripeStatus() === 'active' || this.getStripeStatus() === 'trialing';
    }

    isCanceled() {
        return this.getStripeStatus() === 'canceled';
    }

    getCancelAtPeriodEnd() {
        return this.cancel_at_period_end;
    }

    getType() {
        return this.type;
    }

    getPeriod() {
        return this.period;
    }

    getQuantity() {
        return this.quantity;
    }

    getCurrency() {
        return this.currency;
    }

    isVatIncluded() {
        return this.vat_included;
    }

    getPricePerMonth() {
        return this.price_per_month;
    }

    getPricePerYear() {
        return this.price_per_year;
    }

    getServiceUnitPrice() {
        return this.service_unit_price;
    }

    getServiceCurrency() {
        return this.service_currency;
    }

    getServiceSlug() {
        return this.service_slug;
    }

    getServicePer() {
        return this.service_per;
    }

    getSlots() {
        return this.slots;
    }

    getSlotCount() {
        return this.slot_count;
    }

    getSlotAvailableCount() {
        return this.slot_available_count;
    }

    getSlotUsedCount() {
        return this.slot_used_count;
    }

    getSlotName() {
        return this.slot_name;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }
}

export class Slot implements Deserializable {
    id: number;
    subscription: number;
    account_user: number;
    company: number;
    company_name: string;
    company_user: number;
    company_user_email: string;
    job: number;
    job_title: string;
    created: any;
    created_by: number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getSubscriptionId() {
        return this.subscription;
    }

    getAccountUserId() {
        return this.account_user;
    }

    getCompanyId() {
        return this.company;
    }

    getCompanyName() {
        return this.company_name;
    }

    getCompanyUserId() {
        return this.company_user;
    }

    getCompanyUserEmail() {
        return this.company_user_email;
    }

    getJobId() {
        return this.job;
    }

    getJobTitle() {
        return this.job_title;
    }

    isAvailable() {
        if (!this.account_user && !this.company && !this.company_user && !this.job) {
            return true;
        }
        return false;
    }

    getCreated() {
        return this.created;
    }

    getCreatedBy() {
        return this.created_by;
    }
}


export class Invoice implements Deserializable {
    invoice_id: string;
    customer_id: string;

    amount_due: number;
    amount_paid: number;
    amount_remaining: number;
    currency: string;
    hosted_invoice_url: string;
    attempt_count: number;
    created: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getInvoiceId() {
        return this.invoice_id;
    }

    getCustomerId() {
        return this.customer_id;
    }

    getAmountDue() {
        return this.amount_due;
    }

    getAmountPaid() {
        return this.amount_paid;
    }

    getAmountRemaining() {
        return this.amount_remaining;
    }

    getCurrency() {
        return this.currency;
    }

    getHostedInvoiceUrl() {
        return this.hosted_invoice_url;
    }

    getAttemptCount() {
        return this.attempt_count;
    }

    getCreated() {
        return this.created;
    }
}

export class PaymentMethod implements Deserializable {
    id: string;
    type: string;
    customer: string;
    card: any;
    created: any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

    getId() {
        return this.id;
    }

    getType() {
        return this.type;
    }

    getCustomerId() {
        return this.customer;
    }

    getCardObject() {
        return this.card;
    }

    getCardBrand() {
        return this.card.brand;
    }

    getCardCountry() {
        return this.card.country;
    }

    getCardExpMonth() {
        return this.card.exp_month;
    }

    getCardExpYear() {
        return this.card.exp_year;
    }

    isCardExpired(): boolean {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // January is month 0, so add 1 to match our month parameter

        if (currentYear > this.getCardExpYear()) {
            return true; // Year has passed
        } else if (currentYear === this.getCardExpYear() && currentMonth >= this.getCardExpMonth()) {
            return true; // Year is the same, but month has passed or is the current month
        } else {
            return false; // Year and month are in the future
        }
    }

    getCardLast4() {
        return this.card.last4;
    }

    getCreated() {
        return this.created;
    }
}
