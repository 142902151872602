<div *ngIf="service">
    <div class="mx-auto max-w-7xl">
        <!--
            <div class="mx-auto max-w-2xl sm:text-center">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.</p>
            </div>
        -->
        <div class="bg-white mx-auto mt-3 lg:mx-0">
            <div class="p-3">

                <div class="mb-5 flex justify-center" *ngIf="periods.length > 1">
                    <fieldset class="grid grid-cols-{{ periods.length }} gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
                        <legend class="sr-only">Payment frequency</legend>

                        <label
                          *ngFor="let price of periods"
                          class="cursor-pointer rounded-full px-2.5 py-1"
                          [ngClass]="{'bg-blue-600 text-white': price && service.getSelectedPrice() && service.getSelectedPrice().getId() == price.getId()}">
                            <input type="radio" name="frequency" value="monthly" class="sr-only" (click)="setPrice(price)">
                            <span>{{ price.getPeriod() | titlecase }}</span>
                        </label>
                    </fieldset>
                </div>

                <h3 class="text-2xl font-bold tracking-tight text-gray-900">
                    {{ service.getName() }}
                    <span *ngIf="service.getTrialPeriodDays() > 0"> - {{ 'Try' | translate }} {{ service.getTrialPeriodDays() }}  {{ 'days for free' | translate }}</span>
                </h3>
                <div class="mt-2">
                    <span *ngIf="service.getPeriod() == 'monthly'" class="text-xl font-bold tracking-tight text-gray-900">{{ service.getMinPrice() | number }}&nbsp;</span>
                    <span *ngIf="service.getPeriod() == 'annually'" class="text-xl font-bold tracking-tight text-gray-900">{{ (service.getMinPrice() ?? 0) / 12 | number }}&nbsp;</span>
                    <span>{{ service.getCurrencySymbol() }}</span>
                    <span *ngIf="service.getUseSlots() && service.getSlotName()">&nbsp;per {{ getTranslatedSlotName() }}</span>
                    <span *ngIf="service.getPeriod() == 'monthly'">&nbsp;{{ 'per month' | translate }}</span>
                    <span *ngIf="service.getPeriod() == 'annually' && getSavingsInPercent() > 0">&nbsp;{{ 'per month - billed annually' | translate }} - <b>{{ 'save' | translate }} {{ getSavingsInPercent() | number:'1.0-0' }}%</b></span>
                    <span *ngIf="service.getPeriod() == 'annually' && getSavingsInPercent() == 0">&nbsp;{{ 'per month - billed annually' | translate }}</span>
                    <span *ngIf="service.getUseSlots() && service.getMinSlots() > 1">&nbsp;{{ 'for' | translate }} {{ service.getMinSlots() }} {{ getTranslatedSlotName() }}</span>
                </div>

                <!--
                <pre class="mt-6 text-sm leading-7 text-gray-600" [innerHtml]="service.getDescription(getLanguage())"></pre>

                <div class="mt-10 flex items-center gap-x-4" *ngIf="service.getIncluded()">
                    <h4 class="flex-none text-sm font-semibold leading-6 text-blue-600">{{ 'What’s included' | translate }}</h4>
                    <div class="h-px flex-auto bg-gray-100"></div>
                </div>
                <ul role="list" class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:gap-6" *ngIf="service.getIncluded()">
                    <li class="flex justify-between" *ngFor="let in of service.getIncluded()">
                        <div class="flex gap-x-3">
                            <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                            </svg>
                            {{ in.name }}
                        </div>
                    </li>
                </ul>
                -->
            </div>
            <div class="p-2 w-full">
                <div class="rounded-2xl bg-gray-50 py-8 ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 h-full">
                    <div class="mx-auto px-8">

                        <div *ngIf="!loggedInAccountUser">
                            <app-signup [navigateTo]="router.url" [isCompany]="service.getPer() == 'company' || service.getPer() == 'company_user'" [showTitle]="false" [showLogin]="true"></app-signup>
                            <!--
                                <app-company-create-form [displayStyle]="'mini'"></app-company-create-form>
                            -->
                        </div>

                        <div *ngIf="loggedInAccountUser">
                            <app-subscription
                                 [service]="service"
                                 [price]="service.selected_price"
                                 [companyId]="companyId"
                                 (reloadService)="getService()"
                                 (reloadPricesWithCurrency)="setPriceCurrency($event)"
                                 (activated)="setActivated()"></app-subscription>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="container" *ngIf="!loading && !service">

    <main class="min-h-full max-w-xl mx-auto bg-white py-24 px-6 sm:py-32 lg:px-8">

        <div class="text-center">
            <p class="text-base font-semibold text-indigo-600">404</p>
            <h1 class="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Service unavailable</h1>

            <p class="mt-6 text-base leading-7 text-gray-600">
                Unfortunately, it appears that this service is currently
                unavailable. We kindly ask you to return and select a different
                service or contact our support team if you urgently require this
                specific service.
            </p>
        </div>
    </main>

</div>
