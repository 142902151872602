<app-header [hideSearch]="false"></app-header>

<div class="bg-white">

  <main>
    <!-- Hero section -->
    <div class="relative isolate overflow-hidden bg-gray-900 pb-16 pt-14 sm:pb-20">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
        <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl py-24">
          <div class="text-center">
            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">{{ 'Get Hired Faster with Premium' | translate }}</h1>
            <p class="mt-6 text-lg leading-8 text-gray-300">{{ 'HiCareer unlocks job opportunities that match your skills, experience, and preferences by monitoring the job market for you.' | translate }}</p> <!--  Quickly create tailored resumes for each job with AI. -->
            @if (service && service.getPeriod() == 'annually') {
            <p class="mt-6 text-lg leading-8 text-gray-300">{{ 'From' | translate }} {{ currency }} {{ (service.getMinPrice() ?? 0) / 12 | number }}/{{ 'month' | translate }}. {{ 'Cancel anytime.' | translate }}</p>
            }
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <button (click)="buyPremiumLite.open()" class="btn-blue mt-10">Get started</button>
              <!--
              <a href="#" class="text-sm font-semibold leading-6 text-white">{{ 'View all plans' | translate }} <span aria-hidden="true">→</span></a>
              -->
            </div>
          </div>
        </div>

      </div>
    </div>

    <div>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl py-24">
          <div class="text-center">
            <h2 class="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">{{ 'How Premium Works' | translate }}</h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">{{ 'HiCareer monitors thousands of jobs and companies for you, so you don\'t have to. Get notified when the best-matching jobs tailored to you become available, and easily see how well you match.' | translate }}</p>
          </div>
        </div>
      </div>
    </div>

    <dl class="mx-auto max-w-7xl grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3 text-center">
      <!--
      <div class="flex flex-wrap items-baseline justify-center gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sm font-medium leading-6 text-gray-500 text-center">Monitoring Companies</dt>
        <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">5 000</dd>
      </div>
      -->
      <div class="flex flex-wrap items-baseline justify-center gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sm font-medium leading-6 text-gray-500 text-center">Monitoring Countries</dt>
        <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">3</dd>
      </div>
      <div class="flex flex-wrap items-baseline justify-center gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sm font-medium leading-6 text-gray-500 text-center">Monitoring Jobs</dt>
        <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">3 000+</dd>
      </div>
      <div class="flex flex-wrap items-baseline justify-center gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sm font-medium leading-6 text-gray-500 text-center">HiCareer Users</dt>
        <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">50 000+</dd>
      </div>
    </dl>


    <!-- Feature section -->
    <div class="mt-32 sm:mt-56">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl sm:text-center">
          <h2 class="text-base font-semibold leading-7 text-indigo-600">{{ 'Stop wasting time searching for jobs' | translate }}</h2>
          <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ 'Let the best jobs find you' | translate }}</p>
          <p class="mt-6 text-lg leading-8 text-gray-600">{{ 'Unlock new matching job opportunities, get notified when they become available, and see how well you match with HiCareer Premium.' | translate }}</p>
        </div>
      </div>
      <div class="relative overflow-hidden pt-16">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <img src="https://doublecloud-cdn.s3.eu-central-1.amazonaws.com/media/public/premium/premium-learn-matching.png" alt="App screenshot" class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442">
          <div class="relative" aria-hidden="true">
            <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
          </div>
        </div>
      </div>
      <div class="mx-auto mt-16 max-w-4xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl class="mx-auto grid max-w-4xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:gap-x-8 lg:gap-y-16">
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <i class="bi bi-search absolute left-1 top-1 text-indigo-600 text-xl"></i>
              {{ 'Always Stay Informed About the Best Jobs:' | translate }}
            </dt>
            <dd class="inline">{{ 'HiCareer monitors the job market for you, so you´ll always know about the best jobs that match your skills, experience, and preferences.' | translate }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <i class="bi bi-puzzle absolute left-1 top-1 text-indigo-600 text-xl"></i>
              {{ 'Learn How Well You Match:' | translate }}
            </dt>
            <dd class="inline">{{ 'HiCareer analyzes your profile and job requirements to show your compatibility.' | translate }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <i class="bi bi-bell absolute left-1 top-1 text-indigo-600 text-xl"></i>
              {{ 'Get Notified of Top Opportunities:' | translate }}
            </dt>
            <dd class="inline">{{ 'Receive alerts when the best-matching jobs tailored to you become available.' | translate }}</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <i class="bi bi-check-circle absolute left-1 top-1 text-indigo-600 text-xl"></i>
              {{ 'Apply to the Right Jobs Faster:' | translate }}
            </dt>
            <dd class="inline">{{ 'HiCareer helps you quickly apply to suitable positions, so you can get hired faster.' | translate }}</dd>
          </div>
          <!--
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z" clip-rule="evenodd" />
              </svg>
              Quickly create tailored resumes for each job with AI.
            </dt>
            <dd class="inline">HiCareer helps you create tailored resumes for each job with AI, so you can apply much faster.</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
              </svg>
              Upload your resume and let AI improve it.
            </dt>
            <dd class="inline">Upload your resume and let AI improve it to increase your chances of getting hired.</dd>
          </div>
          <div class="relative pl-9">
            <dt class="inline font-semibold text-gray-900">
              <svg class="absolute left-1 top-1 h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                <path fill-rule="evenodd" d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z" clip-rule="evenodd" />
              </svg>
              Improve your chances of getting hired.
            </dt>
            <dd class="inline">Premium users are more likely to get hired faster. Get ahead of the competition.</dd>
          </div>
          -->
        </dl>
      </div>
    </div>

    <!-- Testimonial section -->
    <!--
    <div class="relative z-10 mt-32 bg-gray-900 pb-20 sm:mt-56 sm:pb-24 xl:pb-0">
      <div class="absolute inset-0 overflow-hidden" aria-hidden="true">
        <div class="absolute left-[calc(50%-19rem)] top-[calc(50%-36rem)] transform-gpu blur-3xl">
          <div class="aspect-[1097/1023] w-[68.5625rem] bg-gradient-to-r from-[#ff4694] to-[#776fff] opacity-25" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
        </div>
      </div>
      <div class="mx-auto flex max-w-7xl flex-col items-center gap-x-8 gap-y-10 px-6 sm:gap-y-8 lg:px-8 xl:flex-row xl:items-stretch">
        <div class="-mt-8 w-full max-w-2xl xl:-mb-8 xl:w-96 xl:flex-none">
          <div class="relative aspect-[2/1] h-full md:-mx-8 xl:mx-0 xl:aspect-auto">
            <img class="absolute inset-0 h-full w-full rounded-2xl bg-gray-800 object-cover shadow-2xl" src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80" alt="">
          </div>
        </div>
        <div class="w-full max-w-2xl xl:max-w-none xl:flex-auto xl:px-16 xl:py-24">
          <figure class="relative isolate pt-6 sm:pt-12">
            <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute left-0 top-0 -z-10 h-32 stroke-white/20">
              <path id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
              <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x="86" />
            </svg>
            <blockquote class="text-xl font-semibold leading-8 text-white sm:text-2xl sm:leading-9">
              <p>Gravida quam mi erat tortor neque molestie. Auctor aliquet at porttitor a enim nunc suscipit tincidunt nunc. Et non lorem tortor posuere. Nunc eu scelerisque interdum eget tellus non nibh scelerisque bibendum.</p>
            </blockquote>
            <figcaption class="mt-8 text-base">
              <div class="font-semibold text-white">Judith Black</div>
              <div class="mt-1 text-gray-400">CEO of Tuple</div>
            </figcaption>
          </figure>
        </div>
      </div>
    </div>
    -->

    <!-- Pricing section -->
    <div class="relative isolate mt-32 bg-white px-6 sm:mt-56 lg:px-8">
      <div class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl" aria-hidden="true">
        <div class="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"></div>
      </div>
      <div class="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 class="text-base font-semibold leading-7 text-blue-600">{{ 'Pricing' | translate }}</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ 'Plans for everyone' | translate }}</p>
      </div>
      <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        {{ 'Everyone deserves to find a job they love and experience financial security. That\'s why we offer affordable plans for everyone.' | translate }}
      </p>


      <div class="mt-6 flex justify-center" *ngIf="periods.length > 0">
        <fieldset class="grid grid-cols-{{ periods.length }} gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <legend class="sr-only">Payment frequency</legend>

          <label
            *ngFor="let price of periods"
            class="cursor-pointer rounded-full px-2.5 py-1"
            [ngClass]="{'bg-blue-600 text-white': price && service.getSelectedPrice() && service.getSelectedPrice().getId() == price.getId()}">
            <input type="radio" name="frequency" value="monthly" class="sr-only" (click)="setPrice(price)">
            <span>{{ price.getPeriod() | titlecase }}</span>
          </label>
        </fieldset>
      </div>


      <div class="mx-auto mt-6 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:gap-y-0 lg:max-w-4xl"> <!--  lg:grid-cols-2 -->
        <div class="rounded-3xl rounded-t-3xl bg-white/60 p-8 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-b-none sm:p-10 lg:mx-0 lg:rounded-3xl"> <!--  lg:rounded-bl-3xl lg:rounded-tr-none -->
          <h3 id="tier-hobby" class="text-base font-semibold leading-7 text-blue-600">Premium Lite</h3>

          <div *ngIf="service" class="mt-2">
              <span *ngIf="service.getPeriod() == 'monthly'" class="text-5xl font-bold tracking-tight text-gray-900">{{ service.getMinPrice() | number }}&nbsp;</span>
              <span *ngIf="service.getPeriod() == 'annually'" class="text-5xl font-bold tracking-tight text-gray-900">{{ (service.getMinPrice() ?? 0) / 12 | number }}&nbsp;</span>
              <span>{{ service.getCurrencySymbol() }}</span>
              <!--
              <span *ngIf="service.getUseSlots() && service.getSlotName()">&nbsp;per {{ getTranslatedSlotName() }}</span>
              -->
              <span *ngIf="service.getPeriod() == 'monthly'">&nbsp;{{ 'per month' | translate }}</span>
              <span *ngIf="service.getPeriod() == 'annually' && getSavingsInPercent() > 0">&nbsp;{{ 'per month - billed annually' | translate }} - <b>{{ 'save' | translate }} {{ getSavingsInPercent() | number:'1.0-0' }}%</b></span>
              <span *ngIf="service.getPeriod() == 'annually' && getSavingsInPercent() == 0">&nbsp;{{ 'per month - billed annually' | translate }}</span>
              <!--
              <span *ngIf="service.getUseSlots() && service.getMinSlots() > 1">&nbsp;{{ 'for' | translate }} {{ service.getMinSlots() }} {{ getTranslatedSlotName() }}</span>
              -->
          </div>


          <p class="mt-6 text-base leading-7 text-gray-600">{{ 'Keep an eye on the job market and discover new opportunities tailored to your skills and preferences.' | translate }}</p>
          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10">
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Automatic Job Monitoring' | translate }}:</span> {{ 'We scan thousands of jobs for you.' | translate }}
              </div>
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Personalized Matches' | translate }}:</span> {{ 'Unlock job opportunities tailored to you.' | translate }}
              </div>
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Match Insights' | translate }}:</span> {{ 'See how well you fit and learn what to improve.' | translate }}
              </div>
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Real-Time Notifications' | translate }}:</span> {{ 'Get alerts when the best jobs become available.' | translate }}
              </div>
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Profile Tracking' | translate }}:</span> {{ 'Know who\'s viewing your profile.' | translate }}
              </div>
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-blue-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              <div>
                <span class="font-medium">{{ 'Premium Badge' | translate }}:</span> {{ 'Boost your visibility with our premium badge.' | translate }}
              </div>
            </li>
          </ul>
          <button (click)="buyPremiumLite.open()" class="btn-blue w-full mt-10">{{ 'Get started' | translate }}</button>
        </div>
        <!--
        <div class="relative rounded-3xl bg-gray-900 p-8 shadow-2xl ring-1 ring-gray-900/10 sm:p-10">
          <h3 id="tier-enterprise" class="text-base font-semibold leading-7 text-indigo-400">Premium</h3>
          <p class="mt-4 flex items-baseline gap-x-2">
            <span class="text-5xl font-bold tracking-tight text-white">$8.9</span>
            <span class="text-base text-gray-400">/month</span>
          </p>
          <p class="mt-6 text-base leading-7 text-gray-300">Dedicated support and infrastructure for your company.</p>
          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-gray-300 sm:mt-10">
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Unlimited products
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Unlimited subscribers
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Advanced analytics
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Dedicated support representative
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Marketing automations
            </li>
            <li class="flex gap-x-3">
              <svg class="h-6 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
              Custom integrations
            </li>
          </ul>
          <a href="#" aria-describedby="tier-enterprise" class="mt-8 block rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 sm:mt-10">Get started today</a>
        </div>
        -->
      </div>
    </div>

    <!-- FAQ section: NOTE FALSE -->
    <div *ngIf="false" class="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
      <div class="mx-auto max-w-4xl divide-y divide-gray-900/10">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
        <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
          <div class="pt-6">
            <dt>
              <!-- Expand/collapse question button -->
              <button type="button" class="flex w-full items-start justify-between text-left text-gray-900" aria-controls="faq-0" aria-expanded="false">
                <span class="text-base font-semibold leading-7">What&#039;s the best thing about Switzerland?</span>
                <span class="ml-6 flex h-7 items-center">
                  <!--
                    Icon when question is collapsed.

                    Item expanded: "hidden", Item collapsed: ""
                  -->
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                  </svg>
                  <!--
                    Icon when question is expanded.

                    Item expanded: "", Item collapsed: "hidden"
                  -->
                  <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
                  </svg>
                </span>
              </button>
            </dt>
            <dd class="mt-2 pr-12" id="faq-0">
              <p class="text-base leading-7 text-gray-600">I don&#039;t know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.</p>
            </dd>
          </div>

          <!-- More questions... -->
        </dl>
      </div>
    </div>
  </main>

</div>


<app-modal #buyPremiumLite [size]="'md'">
  <app-service-subscription
    *ngIf="buyPremiumLite.isOpen()"
    [accountUser]="accountUser"
    [service]="service"></app-service-subscription>
</app-modal>

<!--
    <app-signup *ngIf="signupModal.isOpen()" (loginSuccessful)="signupModal.close()"></app-signup>
-->
