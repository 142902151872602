import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { GeneralModule } from '../_shared_modules/general/general.module';
import { HeaderModule } from '../header/header.module';
import { ModalModule } from '../modal/modal.module';
import { ServiceComponent } from './service.component';
import { SubscriptionComponent } from './subscription/subscription.component';

//import { SignupComponent } from '../signup/signup.component';
import { SignupModule } from '../auth/signup/signup.module';

import { ServiceRoutingModule } from './service-routing.module';
import { SlotTableComponent } from './slot-table/slot-table.component';
import { SlotSetupComponent } from './slot-setup/slot-setup.component';
import { SubscriptionTableComponent } from './subscription-table/subscription-table.component';
import { ServiceListComponent } from './service-list/service-list.component';


@NgModule({
  declarations: [
    ServiceComponent,
    //SubscriptionComponent,
    //SlotTableComponent,
    SlotSetupComponent,
    SubscriptionTableComponent,
    ServiceListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    GeneralModule,
    HeaderModule,
    ModalModule,
    ReactiveFormsModule,
    ServiceRoutingModule,
    //SignupComponent,
    SignupModule,
    TranslateModule.forChild({
        extend: true
    }),
    SubscriptionComponent,
    SlotTableComponent,
  ],
  exports: [
    ServiceComponent,
    //SlotTableComponent,
    SlotSetupComponent,
    //SubscriptionComponent,
    SubscriptionTableComponent,
    ServiceListComponent,
  ]
})
export class ServiceModule { }
