import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Country } from '../_models/country';

import { CountryService } from '../_services/country.service';

@Component({
  selector: 'app-country-select',
    standalone: true,
    imports: [
      CommonModule,
      TranslateModule,
      FormsModule,
      ReactiveFormsModule,
    ],
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit {

    _countryId: number | undefined;

    @Input() set countryId(id: number | undefined) {
        this._countryId = id;
        this.getCountries();
    }

    @Input() required: boolean = false;

    @Input() showLabel: boolean = true;

    @Output() selected = new EventEmitter<Country>();

    countries: Country[];
    country: Country | undefined;

    constructor(
        private countryService: CountryService
    ) { }

    ngOnInit() {
        this.getCountries();
    }

    getCountries(): void {
        this.countryService.getCountries().subscribe(
            countries => {
                this.countries = countries;

                if (this._countryId) {
                    this.country = this.countries.find(country => country.id == this._countryId)
                }
            }
        );
    }

    emitSelectedCountry() {
        this.selected.emit(this.country);
    }

}
